import { useMutation, useQueryClient } from "@tanstack/react-query";
import { displayEngineEarsError } from "../../api/helpers";
import { MUTATION_KEYS } from "../../constants/mutationKeys";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError } from "../../store/actions/errorStore";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { GET_ONBOARDING_PROGRESS } from "../../store/utils/routes";

export enum OnboardingChecklistItemEnum {
  None = "none",
  // ENGINEER
  // PHASE 1
  SERVICE_ADDED = "service_added",
  BOOKING_GENERATED = "booking_generated",
  IN_PROGRESS_PROJECT_STARTED = "in_progress_project_started",
  // PHASE 2
  SETUP_PROFILE = "setup_profile",
  PROMOS_ENABLED = "promos_enabled",
  PROMOTIONAL_ASSETS_DOWNLOADED = "promotional_assets_downloaded",
  PROFILE_SHARED = "profile_shared",
  LINK_ADDED_TO_SOCIALS = "link_added_to_socials",
  // PHASE 3
  BOOKING_ACCEPTED = "booking_accepted",
  UPLOAD_FOR_REVIEW = "upload_for_review",
  PROJECT_COMPLETED = "project_completed",
  CASHED_OUT = "cashed_out",
  // ARTIST
  // PHASE 1
  EXPLORE_PROVIDERS = "explore_providers",
  BOOK_SERVICE = "book_service",
  MAKE_GREAT_MUSIC = "make_great_music",
  UPLOAD_REFERENCE_FILES = "upload_reference_files",
  // PHASE 1 RECORDING
  REVIEW_SESSION_LOCATION = "review_session_location",
  // PHASE 2
  PREVIEW_TRACKS = "preview_tracks",
  APPROVE_PROJECT = "approve_project",
  DOWNLOAD_FINISHED_FILES = "download_finished_files",
  LEAVE_REVIEW = "leave_review",
  // PHASE 2 RECORDING
  ATTEND_SESSION = "attend_session",
  DOWNLOAD_SESSION_FILES = "download_session_files",
  // STUDIO
  // PHASE 1
  INVITE_TEAM = "invite_team",
  LINK_CALENDAR = "link_calendar",
  ADD_ROOM = "add_room",
  ADD_RECORDING_SERVICE = "add_recording_service",
  STUDIO_BOOKING_GENERATED = "studio_booking_generated",
}

interface OnboardingProgressChecklistResponse {
  completed: string | null;
  id: number;
  item: OnboardingChecklistItemEnum;
  type: string;
  user: number;
}

const updateOnboardingProgress = async (
  checklistItem: OnboardingChecklistItemEnum,
) => {
  return makeBackendPostCallWithJsonResponse<OnboardingProgressChecklistResponse>(
    GET_ONBOARDING_PROGRESS,
    { checklist_item: checklistItem },
  );
};

interface UseUpdateOnboardingProgressMutationParams {
  userId: number | undefined;
}

export const useUpdateOnboardingProgressMutation = ({
  userId,
}: UseUpdateOnboardingProgressMutationParams) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.UPDATE_ONBOARDING_PROGRESS, userId],
    mutationFn: async (checklistItem: OnboardingChecklistItemEnum) => {
      const result = await updateOnboardingProgress(checklistItem);
      const jsonResult = result.resultJson;
      if (result.success) {
        return jsonResult;
      }
      throw jsonResult;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_ONBOARDING_PROGRESS, userId],
      });
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return error;
    },
  });
};
