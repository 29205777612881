import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import {
  showBottomAndTopNavAtom,
  showTopNavAtom,
} from "../../../atoms/navAtoms";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  BeatMatrixSubmission,
  BeatMatrixSubmissionsResponse,
  fetchBeatMatrixSubmissions,
} from "../../../store/actions/portfolio";
import { useAppDispatch } from "../../../store/hooks";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import beatMatrixRightSide from "../../../../public/images/beat-matrix-right-side.png";
import beatMatrixLeftSide from "../../../../public/images/beat-matrix-left-side.png";

export const BeatMatrixSubmissions = () => {
  const dispatch = useAppDispatch();
  const [submissions, setSubmissions] = useState<BeatMatrixSubmission[]>([]);

  const { isDesktop } = useMediaQueryBreakpoint();
  const { palette } = useTheme();

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchBeatMatrixSubmissions())
        .unwrap()
        .then((submissionsResponse: BeatMatrixSubmissionsResponse) => {
          setSubmissions(submissionsResponse.submissions);
        });
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container
      sx={{
        backgroundColor: palette.background.paper,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: "32px",
        height: "100vh",
      }}
      id="beat-matrix-screen"
    >
      {isDesktop && (
        <Box
          sx={{
            maxWidth: 300,
            width: "100%",
            flex: 1,
            height: "100vh",
            backgroundColor: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={beatMatrixLeftSide} alt="beat matrix left side" />
        </Box>
      )}
      <Container sx={{ pt: 4, width: "600px", margin: "0px" }}>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <Typography variant="h3">Beat Matrix Submissions</Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!submissions.length && (
            <Grid item xs={12} sx={{ width: "90vw" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <SoundWaveLoader height={100} width={100} />
              </Box>
            </Grid>
          )}
          {submissions.map((submission, index) => (
            <Grid item key={index} xs={12} sx={{ width: "90vw" }}>
              <Typography variant="body1" align="center">
                Title:{" "}
                <strong>
                  {decodeURIComponent(submission.url.split("/").slice(-1)[0])
                    .replace(/\+/g, " ")
                    .replace(".mp3", "")}
                </strong>
              </Typography>
              <Typography variant="body2" align="center">
                Track submitted at{" "}
                <strong>
                  {new Date(submission.timestamp).toLocaleString()}
                </strong>
              </Typography>
              <audio controls src={submission.url} style={{ width: "100%" }} />
            </Grid>
          ))}
        </Grid>
      </Container>
      {isDesktop && (
        <Box
          sx={{
            maxWidth: 300,
            width: "100%",
            flex: 1,
            height: "100%",
            backgroundColor: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={beatMatrixRightSide} alt="beat matrix right side" />
        </Box>
      )}
    </Container>
  );
};
