import { useAtom, useAtomValue } from "jotai";
import "rc-pagination/assets/index.css";
import { Suspense } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Route, Switch, useLocation } from "react-router-dom";
import { consentAtom } from "./atoms/consentAtom";
import { authModalOpenAtom } from "./atoms/location/authModalOpenAtom";
import { displayGenerateBookingInfoAtom } from "./atoms/modals/generatedBookingInfoModal";
import { useMediaQueryBreakpoint } from "./hooks/useMediaQuery";
import routes from "./routes";
import { SCREENS } from "./routes/screens";
import { toggleLoginModal } from "./store/actions/userInterface";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { AuthModal } from "./stories/components/Auth/AuthModal";
import ChatModal from "./stories/components/ChatModal/ChatModal";
import { CookieBanner } from "./stories/components/CookieBanner/CookieBanner";
import { EngineerPromoListModal } from "./stories/components/EngineerPromoListModal/EngineerPromoListModal";
import { GenerateBookingInfo } from "./stories/components/GenerateBookingInfo/GenerateBookingInfo";
import GeneratedAssetViewerModal from "./stories/components/GeneratedAssetViewerModal/GeneratedAssetViewerModal";
import { HubspotFormModal } from "./stories/components/HubspotFormModal/HubspotFormModal";
import { BottomNav } from "./stories/components/Navigation/BottomNav/BottomNav";
import {
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "./stories/components/Navigation/BottomNav/useBottomTabBarOverlayView";
import ChecklistWrapper from "./stories/components/Onboarding/ChecklistWrapper";
import PowerUserControlsHelpModal from "./stories/components/PowerUserControlsHelpModal/PowerUserControlsHelpModal";
import ScrollToTop from "./stories/components/ScrollToTopHoc/ScrollToTopHoc";
import { SearchFiltersPanel } from "./stories/components/SearchFilters/SearchFiltersPanel/SearchFiltersPanel";
import { UnauthenticatedModal } from "./stories/components/UnauthenticatedModal/UnauthenticatedModal";
import { SoundWaveLoader } from "./stories/elements/SoundWaveLoader/SoundWaveLoader";
import WrappedArtistOnboardingSurveyForm from "./stories/forms/ArtistOnboardingSurveyForm/ArtistOnboardingSurveyForm";
import { MaintenanceWrapper } from "./stories/screens/MaintenanceScreen/MaintenanceWrapper";
import { chatModalDisplayAtom } from "./stories/components/ChatModal/atoms";
import ChatSuspense from "./stories/components/ChatInjector/ChatSuspense";

const AuthModalWrapper = () => {
  const authModalOpen = useAtomValue(authModalOpenAtom);
  const { isMobile, isTablet } = useMediaQueryBreakpoint();
  return !isMobile && !isTablet && authModalOpen ? <AuthModal /> : null;
};

const UiContent = () => {
  const dispatch = useAppDispatch();
  const hasConsent = useAtomValue(consentAtom);
  const showModal = useAtomValue(chatModalDisplayAtom);
  const { showLoginModal } = useAppSelector((state) => state.userInterface);
  const location = useLocation();
  const [isGeneratedBookingModalOpen, setIsGeneratedBookingModalOpen] = useAtom(
    displayGenerateBookingInfoAtom,
  );

  const stickyFooterButton = useBottomTabBarOverlayView(
    typeof hasConsent === "undefined",
    <CookieBanner />,
    DEFAULT_TAB_OVERLAY_CLASS,
  );

  return (
    <>
      <ScrollToTop />
      <Suspense
        fallback={
          <div className="container" style={{ margin: "auto" }}>
            <SoundWaveLoader
              width={100}
              height={100}
              styles={{ margin: "auto" }}
            />
          </div>
        }
      >
        <MaintenanceWrapper>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  exact={route.exact ?? true}
                  path={route.path}
                  component={route.component}
                  key={index}
                />
              );
            })}
          </Switch>
        </MaintenanceWrapper>
      </Suspense>
      <AuthModalWrapper />
      <SearchFiltersPanel />
      {/* TODO: Move this modal to the favorites button? and to the livestream page? */}
      <UnauthenticatedModal
        closeModal={() => {
          if (showLoginModal) {
            dispatch(toggleLoginModal());
          }
        }}
        showModal={showLoginModal}
        message={
          location.pathname == SCREENS.LIVESTREAM_SCREEN
            ? "Please sign in to join the stream"
            : "Please sign in to add favorites"
        }
      />
      {showModal && (
        <ChatSuspense>
          <ChatModal />
        </ChatSuspense>
      )}
      {/* TODO: Move this modal to the inside of auth modal  */}
      <WrappedArtistOnboardingSurveyForm />
      <HubspotFormModal />
      <PowerUserControlsHelpModal />
      <EngineerPromoListModal />
      <GenerateBookingInfo
        isOpen={isGeneratedBookingModalOpen}
        setIsOpen={setIsGeneratedBookingModalOpen}
      />
      <GeneratedAssetViewerModal />
      <ChecklistWrapper />
      {stickyFooterButton}
      <BottomNav />
    </>
  );
};

export default UiContent;
