import * as Popover from "@radix-ui/react-popover";
import { useGoogleLogin } from "@react-oauth/google";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";
import { QUERY_KEYS } from "../../../constants/queryKeys";
import useModal from "../../../hooks/useModal";
import {
  connectGoogleTokens,
  removeCalendarIntegration,
} from "../../../store/actions/calendarService";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import GoogleCalendarIcon from "../../assets/google-calendar-icon.png";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import useInvalidateOnboardingProgress from "../../../hooks/onboardingHooks/useInvalidateOnboardingProgress";

interface CalendarManageViewProps {
  studioID?: number;
  showExplanation?: boolean;
  engineerId?: number;
  onRemoveGoogleCalendarIntegration?: () => void;
}

export const CalendarManageView = ({
  studioID,
  showExplanation,
  engineerId,
  onRemoveGoogleCalendarIntegration,
}: CalendarManageViewProps) => {
  const dispatch = useAppDispatch();
  const [googleLoginOpen, setGoogleLoginOpen] = useState(false);
  const {
    calendarIntegrated,
    email,
    loading: loadingCalendar,
  } = useAppSelector((state) => state.calendarService);
  const queryClient = useQueryClient();
  const { invalidateOnboardingProgress } = useInvalidateOnboardingProgress();

  const handleRemovingGoogleCalendarIntegration = () => {
    dispatch(removeCalendarIntegration({ studio_id: studioID }))
      .unwrap()
      .then(() => {
        toast.success("Successfully removed Google Calendar");
        if (engineerId && onRemoveGoogleCalendarIntegration) {
          onRemoveGoogleCalendarIntegration();
          void queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_ENGINEER_SERVICES, engineerId],
          });
        }
      })
      .catch(() => {
        toast.error("Error removing Google Calendar");
      });
  };

  const login = useGoogleLogin({
    onError: () => {
      toast.error(
        "Something went wrong. Please try again or reach out to customer support.",
      );
      setGoogleLoginOpen(false);
    },
    onSuccess: async (codeResponse) => {
      const { code } = codeResponse;
      dispatch(connectGoogleTokens({ code, studio_id: studioID }))
        .unwrap()
        .then((data) => {
          if (data.calendar_integrated) {
            toast.success("Your google calendar has been linked!");
            void invalidateOnboardingProgress();
          } else {
            toast.error(
              "Something went wrong. Make sure to allow Engineears to access your google calendar.",
            );
          }
        })
        .catch(() => {})
        .finally(() => {
          setGoogleLoginOpen(false);
        });
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar",
  });

  const attemptToConnectGoogle = () => {
    setGoogleLoginOpen(true);
    login();
  };

  const {
    isOpen: isPopoverOpen,
    closeModal: closePopover,
    setIsOpen: setIsPopoverOpen,
    openModal: openPopOver,
  } = useModal();

  return (
    <>
      <BasePopover
        isOpen={isPopoverOpen}
        setIsPopoverOpen={setIsPopoverOpen}
        closePopover={closePopover}
        side="top"
        title="Unlink Google Calendar"
        description="Removing your calendar integration will delete your recording service. You will need to re-create the service. Are you sure you want to do this?"
        onConfirm={() => {
          handleRemovingGoogleCalendarIntegration();
        }}
      >
        <Popover.Anchor>
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={calendarIntegrated ? openPopOver : attemptToConnectGoogle}
            disabled={loadingCalendar || googleLoginOpen}
            loading={loadingCalendar || googleLoginOpen}
          >
            <img
              src={GoogleCalendarIcon}
              width={20}
              height={20}
              alt={"Google Calendar"}
              style={{ marginRight: 8 }}
            />
            {calendarIntegrated ? "Unlink" : "Link"} Google Calendar
          </Button>
        </Popover.Anchor>
      </BasePopover>

      {showExplanation && !calendarIntegrated && (
        <Text variant={TextStyleVariant.P2} color={TextColor.SECONDARY}>
          We use Google Calendar to schedule your sessions and save them to your
          calendar.
        </Text>
      )}
      {showExplanation && calendarIntegrated && (
        <Text variant={TextStyleVariant.P2} color={TextColor.SECONDARY}>
          Revoke access to your{" "}
          <Text
            variant={TextStyleVariant.P2}
            weight={TEXT_WEIGHT.SEMI_BOLD}
            style={{ display: "inline" }}
          >
            Google Calendar.
          </Text>{" "}
          This will remove your
          {` ${email ?? ""}`} account from the EngineEars platform and your
          calendar will no longer be synced with EngineEars.
        </Text>
      )}
    </>
  );
};
