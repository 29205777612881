import { Box } from "@mui/material";
import { OptionsDropdown } from "../../core-ui/components/MUIOptionsDropdown/MUIOptionsDropdown";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { OptionType } from "../../elements/DropDownSelector/DropdownSelector";
import { Genre } from "../../../store/models/genres";

const GENRE: OptionType = {
  value: Genre.HIP_HOP,
  label: "Hip Hop",
};
const NOTE: OptionType = { value: 0, label: "C#" };
const BPM: OptionType = { value: 0, label: "90 bpm" };

const GENRE_OPTION = [GENRE];
const NOTE_OPTION = [NOTE];
const BPM_OPTION = [BPM];

export const BeatMatrixDropdown = () => {
  return (
    <Box sx={{ display: "flex", gap: "8px" }}>
      <OptionsDropdown value={GENRE.value} options={GENRE_OPTION} disabled />
      <OptionsDropdown value={NOTE.value} options={NOTE_OPTION} disabled />
      <OptionsDropdown value={BPM.value} options={BPM_OPTION} disabled />
      <Button disabled variant={ButtonVariant.OUTLINED}>
        Undo
      </Button>
    </Box>
  );
};
