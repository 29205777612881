import { isProd } from "../store/utils";

export const getDebugEventUserIdPrefix = isProd ? "" : "-"; // TODO: remove and use the method in src/utils/analyticsUtils instead

export const getDebugEventPrefix = isProd ? "" : "debug_";

export interface AnalyticsEvent {
  name: string;
  data?: Record<string, unknown>;
  options?: SegmentAnalytics.SegmentOpts;
  callback?: (success: boolean) => void;
}

export const trackEvent = (eventData: AnalyticsEvent) => {
  if (!window.analytics) {
    eventData.callback?.(false);
    return;
  }

  const callback = () => {
    eventData.callback?.(true);
  };

  try {
    window.analytics.track(
      `${getDebugEventPrefix}${eventData.name}`,
      eventData.data,
      eventData.options,
      callback,
    );
  } catch {
    eventData.callback?.(false);
    return;
  }
};

export const emitAnalyticsTrackingEvent = (
  name: string,
  data: object,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userId?: number, // @deprecated - This already is included thanks to the identify call
  // TODO: Remove from all usages and remove from function parameters
) => {
  if (!window.analytics) {
    return;
  }

  try {
    window.analytics.track(`${getDebugEventPrefix}${name}`, {
      ...data,
    });
  } catch {
    return;
  }
};

export const identifyAuthenticatedUser = (
  userId: number,
  data: object,
  anonymousId?: string,
) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.identify(
    `${getDebugEventUserIdPrefix}${userId}`,
    {
      ...data,
    },
    {
      anonymousId,
    },
  );
};

export const identifyUnauthenticatedUser = (data: object) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.identify(data);
};
