import copyToClipboard from "copy-to-clipboard";
import { MouseEvent, MouseEventHandler, RefObject } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { INSTANCE_URL, INSTANCE_WS_URL } from "../constants/generated";
import User from "../store/models/user";
import { PhotoDimension } from "../stories/screens/ProfileScreen/constants";

export const REACT_LOCAL_URL = "http://localhost:3000";
const LOCAL_ROOT_URL = "http://localhost:8080";
const STORY_BOOK_URL = "http://localhost:6006";
const PROD_URL = INSTANCE_URL;

const LOCAL_WS_URL = "ws://localhost:8080";
const PROD_WS_URL = INSTANCE_WS_URL;

export const getRootUrl = (): string => {
  if (
    window.location.origin === REACT_LOCAL_URL ||
    window.location.origin === STORY_BOOK_URL
  ) {
    return LOCAL_ROOT_URL;
  }
  return PROD_URL;
};

export const getWsRootUrl = (): string => {
  if (window.location.origin === REACT_LOCAL_URL) {
    return LOCAL_WS_URL;
  }
  return PROD_WS_URL;
};

const removeOrReplaceWhiteSpace = (s: string, pattern: string) => {
  return s.replace(/ +/g, pattern);
};

const formatDashedString = (s: string) => {
  return s ? removeOrReplaceWhiteSpace(s.toLocaleLowerCase().trim(), "-") : s;
};

export { formatDashedString, removeOrReplaceWhiteSpace };

export const isScreenLockSupported = (): boolean => {
  return "wakeLock" in navigator;
};

export const validateEmailList = (list: string[]): boolean => {
  const emails = list;
  let valid = true;
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  for (const item of emails) {
    if (item === "" || !regex.test(item.replace(/\s/g, ""))) {
      valid = false;
    }
  }
  return valid;
};

export const getHost = () => {
  return import.meta.env.DEV
    ? REACT_LOCAL_URL
    : `https://${window.location.hostname}`;
};

export const scrollDown = (ref: RefObject<HTMLElement>) => {
  window.scrollTo({
    top: ref.current?.offsetTop,
    behavior: "smooth",
  });
};

export const obfuscatePhoneNumber = (phoneNumber: string) => {
  return `* *** *** ${phoneNumber.slice(-4)}`;
};

export const setAreEqual = (a: Set<string>, b: Set<string>) =>
  a.size === b.size && [...a].every((x) => b.has(x));

export const roundMinutesToNearestQuarterHour = (minutes: number) =>
  Math.round((minutes / 60) * 4) / 4;

export const convertHttpsLinkToRelativeLinkIfSameDomain = (
  link: string,
  domain: URL,
): string => {
  let url;
  try {
    url = new URL(link);
  } catch (_) {
    return link;
  }
  if (url.hostname === domain.hostname) {
    return url.pathname + url.search + url.hash;
  }
  return link;
};

export function callAllEventHandlers(
  ...handlers: (MouseEventHandler<HTMLButtonElement> | undefined)[]
): MouseEventHandler<HTMLButtonElement> {
  return function (e: MouseEvent<HTMLButtonElement>) {
    handlers.forEach((handler) => handler?.(e));
  };
}

export function callAllHandlersWithData<T>(
  ...handlers: (((data: T) => void) | undefined)[]
): (data: T) => void {
  return function (data: T) {
    handlers.forEach((handler) => handler?.(data));
  };
}

export const computeCropDimensions = (
  sourceDimensions: PhotoDimension,
  targetDimension: PhotoDimension,
) => {
  const { width: sourceWidth, height: sourceHeight } = sourceDimensions;
  const sourceRatio = sourceWidth / sourceHeight;
  const croppingAspectRatio = targetDimension.width / targetDimension.height;

  let croppingWidth, croppingHeight;

  if (sourceRatio < croppingAspectRatio) {
    croppingWidth = sourceWidth;
    croppingHeight = sourceWidth / croppingAspectRatio;
  } else {
    croppingHeight = sourceHeight;
    croppingWidth = sourceHeight * croppingAspectRatio;
  }

  return { croppingWidth, croppingHeight };
};

export const shareOrCopyUrl = async (
  path: string,
  successMessage = "Copied to clipboard!",
) => {
  const shareUrl = `${getHost()}${path}`;
  try {
    if (navigator.share && isMobile) {
      await navigator.share({ url: shareUrl });
    } else {
      copyToClipboard(shareUrl);
    }
    if (successMessage) {
      toast.success(successMessage);
    }
  } catch (error) {
    // The errors here are minor so we don't need to display them
  }
};

export const isUserListenerOnly = (user: User) => {
  return (
    user.listener &&
    !user.listener.deleted &&
    !(
      (user.aandr && !user.aandr.deleted) ||
      (user.admin && !user.admin.deleted) ||
      (user.artist && !user.artist.deleted) ||
      (user.engineer && !user.engineer.deleted) ||
      (user.other && !user.other.deleted) ||
      (user.producer && !user.producer.deleted) ||
      (user.studio_manager && !user.studio_manager.deleted)
    )
  );
};

interface ObjectWithOptionalChildren {
  id: number;
  children?: ObjectWithOptionalChildren[];
}

interface ReducedObject {
  id: number;
  children?: { [key: string]: ReducedObject };
}

export const reduceArrayToObject = <T extends ObjectWithOptionalChildren>(
  list: T[],
): { [key: string]: ReducedObject } => {
  return list.reduce((acc, item: T) => {
    const reducedChildren = item?.children?.length
      ? reduceArrayToObject(item.children)
      : {};
    if (item.children) {
      item = { ...item, children: reducedChildren };
    }
    return { ...acc, [item.id]: item } as ReducedObject;
  }, {});
};

export const checkIfValidEmail = (email: string) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

/**
 * Extracts keys from an object where the corresponding values are true.
 *
 * @param obj - An object with boolean values
 * @returns An array of keys from the input object that have true values
 *
 * @example
 * const options = { a: true, b: false, c: true };
 * const result = getSelectedKeys(options);
 * console.log(result); // ['a', 'c']
 */
export const extractTrueKeys = <T extends string | number | symbol>(
  obj: Record<T, boolean>,
) => {
  return Object.entries(obj)
    .filter(([, value]) => Boolean(value))
    .map(([key]) => key as T);
};

export const supplyChainCredentialsEnabled = (
  scToken: string,
  scExpiration: string,
) => Boolean(scToken && scExpiration) && new Date(scExpiration) > new Date();

export const convertNullToUndefined = <T extends Record<string, any>>(
  obj: T,
): { [K in keyof T]: Exclude<T[K], null> | undefined } => {
  const result: any = {};
  for (const key in obj) {
    result[key] = obj[key] === null ? undefined : obj[key];
  }
  return result;
};

export const getCurrentPositionPromise = (
  options = {},
): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};
