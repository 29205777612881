import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { EngineEarsError, ErrorsAction } from "../../store/actions/errorStore";
import { displayEngineEarsError } from "../../api/helpers";
import { useAtomValue } from "jotai";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import {
  MutateProfileProps,
  postUpdateProfile,
} from "../../api/accounts/user/updateProfile";
import { callAllHandlersWithData } from "../../utils/utils";
import User from "../../store/models/user";

export type MutateSocialMediaProps = Pick<
  MutateProfileProps,
  | "soundcloud_username"
  | "instagram_username"
  | "twitter_username"
  | "facebook_username"
  | "twitch_username"
  | "tiktok_username"
  | "youtube_username"
  | "spotify_username"
  | "apple_username"
  | "amazon_username"
  | "tidal_username"
>;
export type MutateLocationProps = Pick<
  MutateProfileProps,
  "country" | "region" | "city"
>;

export const useUpdateUserProfile = (onSuccess?: (user: User) => void) => {
  const queryClient = useQueryClient();
  const token = useAtomValue(tokenAtom);
  const defaultOnSuccess = async (updatedUser: User) => {
    queryClient.setQueryData(
      [QUERY_KEYS.GET_USER_PROFILE, updatedUser?.username],
      updatedUser,
    );
    queryClient.setQueryData([QUERY_KEYS.LOAD_USER, token], updatedUser);
    return updatedUser;
  };
  return useMutation({
    mutationKey: [QUERY_KEYS.UPDATE_USER_PROFILE, token],
    mutationFn: async (body: MutateProfileProps) => {
      return await postUpdateProfile(body);
    },
    onSuccess: callAllHandlersWithData(defaultOnSuccess, onSuccess),
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
      return { errors: error } as ErrorsAction;
    },
  });
};
