import { Box, Slider, useTheme } from "@mui/material";
import React, { MutableRefObject, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDrum,
  faPiano,
  faMicrophone,
  faGuitarElectric,
  faVolumeUp,
} from "@fortawesome/pro-regular-svg-icons";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import MultiTrack from "./multitrack";

interface BeatMatrixMuteProps {
  multiTrackRef: MutableRefObject<MultiTrack | undefined>;
  muteState: MutableRefObject<boolean[]>;
  volumeState: MutableRefObject<number[]>;
  index: number;
  beatMatrixColors: (index: number, isDisabled?: boolean) => string;
}

export const BeatMatrixMute = ({
  multiTrackRef,
  muteState,
  volumeState,
  index,
  beatMatrixColors,
}: BeatMatrixMuteProps) => {
  const { palette } = useTheme();

  const [localVolume, setLocalVolume] = useState(
    volumeState.current[index] * 100,
  );

  const handleVolumeChange = (index: number, value: number) => {
    if (!multiTrackRef.current) return;
    multiTrackRef.current.setTrackVolume(index, value);
    volumeState.current[index] = value;
    setLocalVolume(value * 100);
  };

  const scaleDown = (value: number) => {
    return value / 100;
  };

  return (
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <Button variant={ButtonVariant.UNSTYLED}>
        <Box
          padding={"12px"}
          sx={() => ({
            backgroundColor: beatMatrixColors(index, localVolume === 0),
            borderRadius: "8px",
          })}
          onClick={() => {
            if (!multiTrackRef.current) return;
            if (muteState.current[index] === false) {
              multiTrackRef.current.setTrackVolume(index, 0);
              muteState.current[index] = true;
              setLocalVolume(0);
            } else {
              const newVolume = volumeState.current[index]
                ? volumeState.current[index]
                : 0.5;
              multiTrackRef.current.setTrackVolume(index, newVolume);
              muteState.current[index] = false;
              volumeState.current[index] = newVolume;
              setLocalVolume(newVolume * 100);
            }
          }}
        >
          <FontAwesomeIcon
            color={
              localVolume === 0 ? palette.text.disabled : palette.text.primary
            }
            icon={
              index === 0
                ? faPiano
                : index === 6
                  ? faMicrophone
                  : index === 1
                    ? faGuitarElectric
                    : faDrum
            }
            size="xl"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            backgroundColor: beatMatrixColors(index, localVolume === 0),
            borderRadius: "8px",
            alignItems: "center",
            justifyContent: "center",
            padding: "12px",
            boxSizing: "border-box",
            marginLeft: "8px",
          }}
        >
          <FontAwesomeIcon icon={faVolumeUp} />
          <Slider
            min={0}
            size="small"
            sx={{ width: "100px" }}
            value={localVolume}
            color="secondary"
            max={100}
            onChange={(e: Event, newValue) => {
              const normalizedValue = scaleDown(newValue as number);
              handleVolumeChange(index, normalizedValue);
            }}
          />
        </Box>
      </Button>
    </Box>
  );
};
