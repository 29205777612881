import { useEffect } from "react";
import { useServiceTypesQuery } from "../../../../hooks/searchHooks/useServiceTypesQuery";
import { setUserLatLngForStudioRoomSearch } from "../../../../store/actions/studioRoomSearch";
import {
  setUserLatLngForUserSearch,
  toggleRequestingLatLong,
} from "../../../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ProjectType } from "../../../../store/models/project";

const useUserLocation = () => {
  const dispatch = useAppDispatch();
  const {
    latitude,
    longitude,
    requestingLatLong: isRequestingLatLong,
  } = useAppSelector((state) => state.userSearch);

  const { serviceTypes: selectedServices } = useServiceTypesQuery();

  useEffect(() => {
    if (latitude > 0 && longitude > 0) {
      return;
    }
    if (
      (selectedServices.includes(ProjectType.RECORDING) ||
        selectedServices.length === 0) &&
      isRequestingLatLong
    ) {
      const onSuccess = (position: GeolocationPosition) => {
        const { latitude, longitude } = position.coords;
        dispatch(setUserLatLngForUserSearch({ latitude, longitude }));
        dispatch(setUserLatLngForStudioRoomSearch({ latitude, longitude }));
        dispatch(toggleRequestingLatLong());
      };
      const onFail = () => {
        dispatch(toggleRequestingLatLong());
      };
      window.navigator.geolocation.getCurrentPosition(onSuccess, onFail);
    }
  }, [selectedServices, dispatch, isRequestingLatLong, latitude, longitude]);
};

export default useUserLocation;
