import { useMemo } from "react";
import { ProjectType } from "../../store/models/project";
import {
  parseQueryStringAsNumberArray,
  parseUrlQueryParams,
} from "../../utils/searchFiltersUtils";
import { useUpdateSearchFilters } from "./useUpdateSearchFilters";
import { SearchFiltersQueryParams, SearchScreenParamKeys } from "./utils";

export const useServiceTypesQuery = () => {
  const parsedQueryParams = parseUrlQueryParams();
  const updateSearchFilters = useUpdateSearchFilters();
  const serviceTypesValue =
    parsedQueryParams[SearchScreenParamKeys.ServiceTypes];

  const serviceTypes = useMemo(
    () =>
      parseQueryStringAsNumberArray(serviceTypesValue).filter((el) =>
        Object.values(ProjectType).includes(el),
      ) as ProjectType[],
    [serviceTypesValue],
  );

  const setServiceType = (serviceType: ProjectType) => {
    let newServiceTypes: ProjectType[] = [];
    const updatedSearchQueryObject = {} as SearchFiltersQueryParams;

    if (serviceType === ProjectType.MIXING) {
      if (
        serviceTypes.includes(ProjectType.TWO_TRACK_MIXING) ||
        serviceTypes.includes(ProjectType.MIXING)
      ) {
        const updatedServiceTypes = serviceTypes.filter(
          (service) =>
            service !== ProjectType.TWO_TRACK_MIXING &&
            service !== ProjectType.MIXING,
        );

        newServiceTypes = updatedServiceTypes;
      } else {
        newServiceTypes = [
          ...serviceTypes,
          ProjectType.MIXING,
          ProjectType.TWO_TRACK_MIXING,
        ];
      }
    } else {
      if (serviceTypes.includes(serviceType)) {
        newServiceTypes = serviceTypes.filter(
          (service) => service !== serviceType,
        );
      } else {
        newServiceTypes = [...serviceTypes, serviceType];
      }
    }

    if (newServiceTypes.length === 5 || serviceType === ProjectType.NO_TYPE) {
      updatedSearchQueryObject[SearchScreenParamKeys.ServiceTypes] = undefined;
    } else {
      updatedSearchQueryObject[SearchScreenParamKeys.ServiceTypes] =
        newServiceTypes.join(",");
    }

    updateSearchFilters({ updatedSearchQueryObject });
  };

  const setServiceTypes = (newServiceTypes: ProjectType[]) => {
    updateSearchFilters({
      updatedSearchQueryObject: {
        [SearchScreenParamKeys.ServiceTypes]: newServiceTypes.join(","),
      },
    });
  };

  return { setServiceType, serviceTypes, setServiceTypes };
};
