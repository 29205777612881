import { useHistory } from "react-router-dom";
import { useGenresQuery } from "../../../hooks/searchHooks/useGenresQuery";
import { useServiceTypesQuery } from "../../../hooks/searchHooks/useServiceTypesQuery";
import { SCREENS } from "../../../routes/screens";
import { setAutoCompleteSuggestions } from "../../../store/actions/userSearch";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  AutocompleteSuggestion,
  AutoCompleteTypeEnum,
} from "../../../store/models/autocomplete";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { SoundWaveLoader } from "../../elements/SoundWaveLoader/SoundWaveLoader";
import { EntitySearchResultListItem } from "./EntitySearchResultItem";
import "./EntitySearchResultList.css";

export interface EntitySearchResultListProps {
  className?: string;
  loading?: boolean;
  data: AutocompleteSuggestion[];
  onListItemClick: () => void;
}

export const EntitySearchResultList = ({
  className,
  data,
  onListItemClick,
  loading,
}: EntitySearchResultListProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.accountInfo);
  const userId = user?.id;
  const { genres, setGenres } = useGenresQuery();
  const { serviceTypes, setServiceTypes } = useServiceTypesQuery();

  const onAutocompleteItemClick = (suggestion: AutocompleteSuggestion) => {
    emitAnalyticsTrackingEvent(
      "search_autocomplete_selection",
      { autocomplete_suggestion: suggestion },
      userId,
    );
    const { type } = suggestion;
    const isUser = type === AutoCompleteTypeEnum.USER;
    const isStudio = type === AutoCompleteTypeEnum.STUDIO;

    // clear the search term atom
    onListItemClick();

    if (isUser) {
      const { username } = suggestion;
      history.push(getProfileScreenRoute(username));
    } else if (isStudio) {
      const { username } = suggestion;
      history.push(getStudioScreenRoute(username));
    }
    if (history.location.pathname !== SCREENS.SEARCH && !isUser && !isStudio) {
      history.push(SCREENS.SEARCH);
    }
    dispatch(
      setAutoCompleteSuggestions({
        autocompleteSuggestion: suggestion,
        genres,
        updateGenresFilter: setGenres,
        serviceTypes,
        updateServiceTypesFilter: setServiceTypes,
      }),
    );
  };

  if (!loading && data.length === 0) {
    return null;
  }
  return (
    <div
      style={
        !loading && data.length < 5
          ? {
              height: "fit-content",
              maxHeight: "unset",
            }
          : {}
      }
      className={`entity-search-result-list ${loading ? "loading-autocomplete-results" : ""} ${className}`}
    >
      {loading && <SoundWaveLoader height={100} width={100} />}
      {!loading &&
        data.map((item: AutocompleteSuggestion, index: number) => {
          return (
            <EntitySearchResultListItem
              key={index}
              onListItemClick={onAutocompleteItemClick}
              autocompleteItem={item}
            />
          );
        })}
    </div>
  );
};
