import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { activeProfileAtom } from "../../../../atoms/user/activeProfileAtom";
import { ProfileType } from "../../../../store/models/base";
import { setStudio, setUser } from "../../../../store/actions/selectedProfile";
import { useHistory, useLocation } from "react-router-dom";
import pathnameAtom from "../../../../atoms/location/pathnameAtom";
import { PAGE_HASH, hashAtom } from "../../../../atoms/location/locationAtom";

const useSyncProfileState = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const setPathname = useSetAtom(pathnameAtom);
  const [hash, setHash] = useAtom(hashAtom);
  const activeProfile = useAtomValue(activeProfileAtom);
  const activeProfileSlice = useAppSelector(
    (state) => state.selectedProfileSlice,
  );
  const user = useAppSelector((state) => state.accountInfo.user);
  const studios = useAppSelector((state) => state.accountInfo.userStudios);
  useEffect(() => {
    const isActiveProfileStudio =
      activeProfile?.type === ProfileType.STUDIO && studios;
    const isActiveProfileSliceStudio =
      activeProfile && activeProfileSlice.studio?.id === activeProfile.id;
    if (isActiveProfileStudio && !isActiveProfileSliceStudio) {
      const studio = studios.find((s) => s.id === activeProfile.id);
      if (studio) {
        dispatch(setStudio(studio));
        return;
      }
    }
    const isActiveProfileUser =
      activeProfile?.type === ProfileType.USER && user;
    const isActiveProfileSliceUser =
      activeProfile && activeProfileSlice.user?.id === activeProfile.id;

    if (isActiveProfileUser && !isActiveProfileSliceUser) {
      dispatch(setUser(user));
      return;
    }
  }, [
    activeProfile,
    user,
    studios,
    activeProfileSlice.user,
    activeProfileSlice.studio,
    dispatch,
  ]);

  // We need to sync the location pathname from React Router with an atom
  // otherwise we cannot accurately access the active pathname in our atoms
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname, setPathname]);

  useEffect(() => {
    setHash(location.hash);
  }, [location.hash, setHash]);

  useEffect(() => {
    if (hash === PAGE_HASH.CLEARED) {
      history.replace({ ...location, hash: "" });
    }
  }, [hash, history]);
};

export default useSyncProfileState;
