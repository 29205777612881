import {
  faBell as bell,
  faEnvelope as message,
  faUser as user,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBell as bellSolid,
  faComputer as computer,
  faEnvelope as messageSolid,
  faHeadphones as headphonesSolid,
  faHeadphonesSimple as headphones,
  faPlus as signUp,
  faPlusCircle as signUpSolid,
  faSearch as search,
  faSearchPlus as searchSolid,
  faSignIn as signIn,
  faSignInAlt as signInSolid,
  faUser as userSolid,
  faUsers as communitySolid,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/pro-light-svg-icons";
import {
  faFolderMusic,
  faHouse,
  faListMusic,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom, useSetAtom } from "jotai";
import { useMemo } from "react";
import { AuthFlows } from "../../../../constants/authSteps";
import { useAppSelector } from "../../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { Icon, IconType } from "../../../core-ui/components/Icon/Icon";
import {
  NAV_BUTTON_VARIANT,
  NavButton,
} from "../NavigationRow/components/NavButton/NavButton";
import { NavMessagebutton } from "../NavigationRow/components/NavMessageButton/NavMessageButton";
import {
  NAV_NOTIFICATION_VARIANT,
  NavNotificationButton,
} from "../NavigationRow/components/NavNotificationButton/NavNotificationButton";
import { getNavButtonColor } from "../utils";
import { authModalOpenAtom } from "../../../../atoms/location/authModalOpenAtom";
import { currentFlowAtom } from "../../../../atoms/location/currentFlowAtom";
import ChatSuspense from "../../ChatInjector/ChatSuspense";

export enum NavTabButtonEnum {
  ADMIN_DASHBOARD,
  PROJECTS,
  MESSAGES,
  SEARCH,
  NOTIFICATIONS,
  DASHBOARD,
  PROFILE,
  ARTIST_HOME,
  ENGINEER_HOME,
  SIGN_IN,
  SIGN_UP,
  COMMUNITY,
  MY_LIBRARY,
}

export interface NavItemProps {
  currentPath: string;
  navTabEnum: NavTabButtonEnum;
  title: string;
  path: string;
  linkPaths?: string[];
  showIconOnly?: boolean;
}

interface iconsParams {
  icon: IconDefinition;
  iconSolid: IconDefinition;
}

const iconsMap = new Map<NavTabButtonEnum, iconsParams>([
  [
    NavTabButtonEnum.ADMIN_DASHBOARD,
    {
      icon: faHouse,
      iconSolid: faHouse,
    },
  ],
  [
    NavTabButtonEnum.DASHBOARD,
    {
      icon: faHouse,
      iconSolid: faHouse,
    },
  ],
  [
    NavTabButtonEnum.MESSAGES,
    {
      icon: message,
      iconSolid: messageSolid,
    },
  ],
  [
    NavTabButtonEnum.COMMUNITY,
    {
      icon: faUsers,
      iconSolid: communitySolid,
    },
  ],
  [
    NavTabButtonEnum.PROJECTS,
    {
      icon: faFolderMusic,
      iconSolid: faFolderMusic,
    },
  ],
  [
    NavTabButtonEnum.MY_LIBRARY,
    {
      icon: faListMusic,
      iconSolid: faListMusic,
    },
  ],
  [
    NavTabButtonEnum.NOTIFICATIONS,
    {
      icon: bell,
      iconSolid: bellSolid,
    },
  ],
  [
    NavTabButtonEnum.SEARCH,
    {
      icon: search,
      iconSolid: searchSolid,
    },
  ],
  [
    NavTabButtonEnum.PROFILE,
    {
      icon: user,
      iconSolid: userSolid,
    },
  ],
  [
    NavTabButtonEnum.ARTIST_HOME,
    {
      icon: headphones,
      iconSolid: headphonesSolid,
    },
  ],
  [
    NavTabButtonEnum.ENGINEER_HOME,
    {
      icon: computer,
      iconSolid: computer,
    },
  ],
  [
    NavTabButtonEnum.SIGN_IN,
    {
      icon: signIn,
      iconSolid: signInSolid,
    },
  ],
  [
    NavTabButtonEnum.SIGN_UP,
    {
      icon: signUp,
      iconSolid: signUpSolid,
    },
  ],
]);

export const NavItem = ({
  currentPath,
  navTabEnum,
  title,
  path,
  linkPaths = [],
  showIconOnly = false,
}: NavItemProps) => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const [flow, setFlow] = useAtom(currentFlowAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);

  const iconColor = getNavButtonColor(
    [path, ...linkPaths],
    currentPath,
    undefined,
    navTabEnum,
    flow,
  );

  const icon = useMemo(() => {
    const iconObj = iconsMap.get(navTabEnum) ?? null;
    if (iconObj) {
      return iconObj.icon;
    }
  }, [title]);

  const handleSelectIcon = (title: string, navTabEnum?: NavTabButtonEnum) => {
    emitAnalyticsTrackingEvent(`clicked_bottom_nav_${title}`, {}, user?.id);

    if (navTabEnum === NavTabButtonEnum.SIGN_UP) {
      setFlow(AuthFlows.START_SIGNUP);
      setAuthModalOpen(true);
    } else if (navTabEnum === NavTabButtonEnum.SIGN_IN) {
      setFlow(AuthFlows.LOGIN);
      setAuthModalOpen(true);
    }
  };

  if (navTabEnum === NavTabButtonEnum.MESSAGES) {
    return (
      <ChatSuspense>
        <NavMessagebutton
          iconColor={iconColor}
          onClick={() => handleSelectIcon("Messages")}
          user={user}
          showIconOnly={showIconOnly}
        />
      </ChatSuspense>
    );
  }

  if (navTabEnum === NavTabButtonEnum.NOTIFICATIONS) {
    return (
      <NavNotificationButton
        iconColor={iconColor}
        onClick={() => handleSelectIcon("Notifications")}
        user={user}
        variant={NAV_NOTIFICATION_VARIANT.LINK}
        showIconOnly={showIconOnly}
      />
    );
  }

  if (navTabEnum === NavTabButtonEnum.COMMUNITY) {
    return (
      <NavButton
        variant={NAV_BUTTON_VARIANT.LINK}
        color={iconColor}
        frontIcon={
          <Icon
            fill={iconColor}
            height="21"
            type={IconType.Group}
            viewBox="0 0 24 21"
          />
        }
        onClick={() => handleSelectIcon(title)}
        message={title}
        path={path}
        showIconOnly={showIconOnly}
      />
    );
  }

  return (
    <NavButton
      variant={NAV_BUTTON_VARIANT.LINK}
      color={iconColor}
      frontIcon={
        icon && <FontAwesomeIcon icon={icon} color={iconColor} size="xl" />
      }
      onClick={() => handleSelectIcon(title, navTabEnum)}
      message={title}
      path={path}
      showIconOnly={showIconOnly}
    />
  );
};
