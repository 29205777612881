import { useMemo } from "react";
import { Genre } from "../../store/models/genres";
import {
  parseQueryStringAsNumberArray,
  parseUrlQueryParams,
} from "../../utils/searchFiltersUtils";
import { useUpdateSearchFilters } from "./useUpdateSearchFilters";
import { SearchScreenParamKeys } from "./utils";

export const useGenresQuery = () => {
  const parsedQueryParams = parseUrlQueryParams();
  const updateSearchFilters = useUpdateSearchFilters();
  const genresValue = parsedQueryParams[SearchScreenParamKeys.Genres];

  const genres = useMemo(
    () =>
      parseQueryStringAsNumberArray(genresValue).filter((el) =>
        Object.values(Genre).includes(el),
      ) as Genre[],
    [genresValue],
  );

  const setGenres = (newGenres: Genre[]) => {
    updateSearchFilters({
      updatedSearchQueryObject: {
        [SearchScreenParamKeys.Genres]: newGenres.join(","),
      },
    });
  };

  return { genres, setGenres };
};
