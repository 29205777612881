import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Container, TextField, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  PlaybackControlButton,
  PlaybackControlButtonVariant,
} from "../../elements/PlaybackControlButton/PlaybackControlButton";
import { BeatMatrixMute } from "./BeatMatrixMute";
import { default as MultiTrack, default as Multitrack } from "./multitrack";
import { useBeatMatrixColors } from "./utils";
import { readStream } from "../../../store/utils/fetch";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { BeatMatrixDropdown } from "./BeatMatrixDropdown";
import beatMatrixRightSide from "../../../../public/images/beat-matrix-right-side.png";
import beatMatrixLeftSide from "../../../../public/images/beat-matrix-left-side.png";
import { toast } from "react-toastify";
import {
  submitBeatMatrixBeat,
  SubmitBeatMatrixResponse,
} from "../../../store/actions/portfolio";
import { useAppDispatch } from "../../../store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { useSetAtom } from "jotai";
import {
  showBottomAndTopNavAtom,
  showTopNavAtom,
} from "../../../atoms/navAtoms";
import _, { shuffle } from "lodash";

// const baseUrl = "https://storage.googleapis.com/engineears-static/audio/beatmatrix/";
const baseUrl = "audio/beatmatrix/";

const vocalsUrls = [
  "vocals/LaRussel_6 Figure Agreements.m4a",
  "vocals/LaRussel_All Around The Bay.m4a",
  "vocals/LaRussel_Change All I Know.m4a",
  "vocals/LaRussel_Hustle Pt 2.m4a",
  "vocals/LaRussel_Hustle.m4a",
  "vocals/LaRussel_Made It To The League.m4a",
  "vocals/LaRussel_Nobody Signed Me.m4a",
  "vocals/LaRussel_Plague My Mind.m4a",
  "vocals/LaRussel_Read The Comments.m4a",
];

const percussionUrls = [
  "percussion/(PERC LOOP) 141 BPM @BNYX-24b_1.m4a",
  "percussion/(PERC LOOP) BLEEP 140 BPM KICK RIM @BNYX-24b_1.m4a",
  "percussion/(PERC LOOP) NO SNARE OTHER SIDE 137 BPM @BNYX-24b_1.m4a",
  "percussion/(PERC LOOP) OZARK 94 BPM @BNYX-24b_1.m4a",
  "percussion/(PERC LOOP) THE SUITE LIFE 140 BPM @BNYX-24b_1.m4a",
  "percussion/NBA Perc Lp 1_Twlilight Glow.m4a",
  "percussion/NBA Perc Lp 2_Polygone.m4a",
  "percussion/NBA Perc Lp 3_On Island Time Cowbell.m4a",
  "percussion/NBA Perc Lp 4_Perc Swirl.m4a",
  "percussion/NBA Perc Lp 5_Cosmic Slide.m4a",
  "percussion/NBA Perc Lp 6_Cosmic Slide Congos.m4a",
  "percussion/NBA Perc Lp 7_Warning Signs.m4a",
  "percussion/NBA Perc Lp 8_Congos and Bongos.m4a",
  "percussion/NBA Perc Lp 9_Congos and Bongos.m4a",
  "percussion/NBA Perc LP 10_Brush Perc.m4a",
  "percussion/NBA Perc Lp 11_Congos and Bongos Shaker.m4a",
  "percussion/NBA Perc Lp 12_Congo Bongo Perc.m4a",
  "percussion/NBA Perc Lp 13_Workshop Perc.m4a",
  "percussion/NBA Perc Lp 14_Workshop Perc.m4a",
  "percussion/NBA Perc Lp 15_Workshop Perc OH.m4a",
  "percussion/NBA Perc Lp 17_Scratch and Claw_Tamb and Shaker.m4a",
  "percussion/NBA Perc Lp 18_Bongo Latin.m4a",
  "percussion/NBA Perc Lp 19_Hand Drum_Slow Chop.m4a",
  "percussion/NBA Perc Lp 20_Perfectly Content.m4a",
  "percussion/NBA Perc Lp 21_Rhythm Tik Tak.m4a",
  "percussion/NBA Perc Lp 22_Rhythm TIk Tak.m4a",
  "percussion/NBA Perc Lp 23_Chiller Tops.m4a",
  "percussion/NBA Perc Lp 24_Chiller Tops_chop.m4a",
  "percussion/NBA Perc Lp 25_By Candlelight.m4a",
  "percussion/NBA Percl Lp 16_Elephant Stomp.m4a",
  "percussion/NBA Percl Lp 16_Rev Vibe_Stratos.m4a",
  "percussion/TAMB LOOP 140BPM-24b_1.m4a",
  "percussion/Tamb Shaker Loop 2_1.m4a",
  "percussion/Tamb Shaker Loop 3_1.m4a",
  "percussion/Tamb Shaker Loop 4_1.m4a",
  "percussion/Tamb Shaker Loop 5_1.m4a",
  "percussion/Tamb Shaker Loop 6_1.m4a",
  "percussion/Tamb Shaker Loop 7_1.m4a",
  "percussion/Tamb Shaker Loop_1.m4a",
  "percussion/tambourine_loop_2.m4a",
  "percussion/tambourine_loop_3.m4a",
  "percussion/The Alchemists Percussion Loop 1 (111BPM)-24b_1.m4a",
  "percussion/The Alchemists Percussion Loop 2-24b_1.m4a",
  "percussion/The Big Dudes Tamborine Loop-24b_1.m4a",
];

const bassUrls = [
  "bass/ALF3_Kit_03_808_Sub_Kick_Loop_71.m4a",
  "bass/BO_HWD_135_Bass_Loop_808_Patch_Cm_1.m4a",
  "bass/DDG_Cm_120_808_loop_1.m4a",
  "bass/ELX_NRW_808_loop_07_150_Cm_1.m4a",
  "bass/FL_TR_Kit02_Bass_Loop_808_Distorted_100_Cm_1.m4a",
  "bass/NBA 808 1.m4a",
  "bass/NBA 808 2.m4a",
  "bass/NBA 808 3.m4a",
  "bass/NBA 808 5_1.m4a",
  "bass/NBA 808 10.m4a",
  "bass/NBA 808 11.m4a",
  "bass/NBA 808 32_Good Day.m4a",
  "bass/NBA Bass 13.m4a",
  "bass/NBA Bass Pluck 14.m4a",
  "bass/NBA Brass Bass 31_Determined.m4a",
  "bass/NBA DIST 808 25.m4a",
  "bass/NBA DIST 808 26.m4a",
  "bass/NBA DIST 808 27.m4a",
  "bass/NBA DIST 808 28.m4a",
  "bass/NBA Funk Bass 34_Glorify.m4a",
  "bass/NBA Groove Bass 35_Crazy For You_1.m4a",
  "bass/NBA Lit Bass Synth 15.m4a",
  "bass/NBA Lit Bass Synth 16.m4a",
  "bass/NBA Live Bass 17.m4a",
  "bass/NBA Live Bass 18.m4a",
  "bass/NBA Live Bass 19.m4a",
  "bass/NBA Live Bass 20.m4a",
  "bass/NBA Live Bass 21.m4a",
  "bass/NBA Live Bass 23.m4a",
  "bass/NBA LIVE BASS 29.m4a",
  "bass/NBA LIVE BASS 30.m4a",
  "bass/NBA Live Bass Pluck 24.m4a",
  "bass/NBA Sub Bass 33_All Or Nothing.m4a",
  "bass/NBA Synth Bass 4.m4a",
  "bass/NBA Synth Bass 6.m4a",
  "bass/NBA Synth Bass 7.m4a",
  "bass/NBA Synth Bass 9.m4a",
  "bass/NBA Synth Bass 12.m4a",
  "bass/NBA Synth Bass 22 merged.m4a",
  "bass/NBA Walk Synth Bass 8.m4a",
  "bass/NOIS_FUJIWATER_808_2.m4a",
];

const hatsUrls = [
  "hats/ABBP_Trap_Hat_Q_Rock_Loop_02_134BPM_1.m4a",
  "hats/ABBP_Trap_Hat_SVGAR_Loop_03_160bpm_1.m4a",
  "hats/ABBP_Trap_Hat_SVGAR_Loop_05_140bpm_1.m4a",
  "hats/ABBP_Trap_Hat_SVGAR_Loop_07_130bpm_1.m4a",
  "hats/ABTI3_Q_Rock_Hat_Loop_Trap_In_Eden_140BPM_1.m4a",
  "hats/DECAP_89_hihat_loop_dark_swing_bouncy_boom_bap_1.m4a",
  "hats/OSS_LS1_89_HAT_LOOP_ISLAND_Krs_GROOVE_1.m4a",
  "hats/PMTH_Cymbal_Loop_89_03.m4a",
  "hats/bb_drum_loop_broke_hat_90.m4a",
  "hats/nois2021_Hats_04.m4a",
  "hats/nois2021_Hats_05.m4a",
  "hats/nois2021_Hats_06.m4a",
  "hats/nois2021_Hats_10.m4a",
  "hats/nois2021_Hats_13.m4a",
  "hats/nois2021_Hats_14.m4a",
  "hats/nois2021_Hats_15.m4a",
  "hats/nois2021_Hats_16.m4a",
  "hats/nois2021_Hats_17.m4a",
  "hats/nois2021_Hats_22.m4a",
  "hats/nois2021_Hats_23.m4a",
  "hats/nois2021_Hats_25.m4a",
  "hats/nois2021_Hats_29.m4a",
  "hats/nois2021_Hats_38.m4a",
  "hats/nois2021_Hats_45.m4a",
  "hats/nois2021_Hats_46.m4a",
  "hats/nois2021_Hats_47.m4a",
  "hats/nois2021_Hats_48.m4a",
  "hats/nois2021_Hats_56.m4a",
  "hats/nois2021_Hats_61.m4a",
  "hats/nois2021_Hats_62.m4a",
  "hats/nois2021_Hats_63.m4a",
  "hats/nois2021_Hats_64.m4a",
  "hats/nois2021_Hats_65.m4a",
  "hats/nois2021_Hats_66.m4a",
  "hats/nois2021_Hats_67.m4a",
  "hats/nois2021_Hats_68.m4a",
  "hats/nois2021_Hats_69.m4a",
  "hats/nois2021_Hats_70.m4a",
  "hats/nois2021_Hats_71.m4a",
  "hats/nois2021_Hats_72.m4a",
  "hats/nois2021_Hats_73.m4a",
  "hats/nois2021_Hats_74.m4a",
  "hats/nois2021_Hats_75.m4a",
  "hats/nois2021_Hats_76.m4a",
  "hats/nois2021_Hats_77.m4a",
  "hats/nois2021_Hats_78.m4a",
  "hats/nois2021_Hats_79.m4a",
  "hats/nois2021_Hats_80.m4a",
  "hats/nois2021_Hats_81.m4a",
  "hats/nois2021_Hats_82.m4a",
  "hats/nois2021_Hats_83.m4a",
  "hats/nois2021_Hats_84.m4a",
  "hats/nois2021_Hats_85.m4a",
  "hats/nois2021_Hats_86.m4a",
  "hats/nois2021_Hats_87.m4a",
  "hats/nois2021_Hats_88.m4a",
];

const kickUrls = [
  "kick/BNYX KICK_2.m4a",
  "kick/BNYX KICK.m4a",
  "kick/NBA Kick 1_ Beat Buffet Acoustic Kick.m4a",
  "kick/NBA Kick 2_Beat Buffet Acoustic1.m4a",
  "kick/NBA Kick 3_Change of Rain.m4a",
  "kick/NBA Kick 4_Revenge_grind city.m4a",
  "kick/NBA Kick 5_Playiing With Fire.m4a",
  "kick/NBA Kick 6_WeatherTheStorm.m4a",
  "kick/NBA Kick 7_Twilight Glow.m4a",
  "kick/NBA Kick 8_OnIslandTime.m4a",
  "kick/NBA Kick 9_Vastly Superior.m4a",
  "kick/NBA Kick 11_Tomb Of The Alpha.m4a",
  "kick/NBA Kick_10_Serpentine.m4a",
  "kick/nois kick_2.m4a",
  "kick/NOIS_Kick_Jealousy_98.m4a",
  "kick/NOIS_Kick_Link Up_2.m4a",
  "kick/nois_kick_tidings_61.m4a",
  "kick/STSH KICKS_1.m4a",
  "kick/STSH KICKS_3.m4a",
  "kick/STSH KICKS.m4a",
];

const sampleUrls = [
  "samples/! dark Performance 130 Cmin @iuligans_beats_1.m4a",
  "samples/!![Jerk] Strength 97-194bpm Cm [@1ts_katzy]_1.m4a",
  "samples/!''Conexión -Sample'' (100 Bpm- Cm) Prod.STAGIO_1.m4a",
  "samples/![New Jazz] Realest 140bpm Cm [@1ts_katzy]_1.m4a",
  "samples/''1989-Sample'' (130 Bpm- Cm) Prod.STAGIO_1.m4a",
  "samples/''All a Dream-Sample'' (120 Bpm- Cm) Prod.  STAGIO_1.m4a",
  "samples/''Caution-Sample'' (92 Bpm- Cm) Prod.  STAGIO_1.m4a",
  "samples/''Down Hill -Sample'' (136 Bpm- Cm) Prod.  STAGIO_1.m4a",
  "samples/''Down On Me-Sample'' (142 Bpm- Cm) Prod.STAGIO_1.m4a",
  "samples/''Emeralds-Sample'' (100 Bpm- Cm) Prod.  STAGIO_1.m4a",
  "samples/''Hold That-Sample'' (150 Bpm- Cm) Prod.STAGIO_1.m4a",
  "samples/''Many Places-Sample'' (127 Bpm- Cm) Prod.STAGIO_1.m4a",
  "samples/''Old Ways -Sample'' (120 Bpm- Cm) Prod.STAGIO_1.m4a",
  "samples/''Rain On Me -Sample'' (131 Bpm- Cm) Prod.  STAGIO_1.m4a",
  "samples/''Ski-Sample'' (125 Bpm- Cm) Prod.STAGIO_1.m4a",
  "samples/''Wait-Sample'' (83 Bpm- Cm) Prod.STAGIO_1.m4a",
  "samples/(150) (smooth) ceaser @dracomadeit x @donez c#min_1.m4a",
  "samples/(Loe Shimmy, Luh Tyler) On My Way 'Amin' 144 @prodmadebyjay_1.m4a",
  "samples/(soul, jazz) bdlw 80bpm - Cminor - @do.r3prod @dstbeats__1.m4a",
  "samples/[Afro, Dancehall] Numb 99bpm Cm [@1ts_katzy]_1.m4a",
  "samples/[Afro] No Sleep 105bpm Cm [@1ts_katzy x @g-sign @prod.malef]_1.m4a",
  "samples/[Ambient] ILY 130bpm Cm [@1ts_katzy]_1.m4a",
  "samples/[STARTER] KEY 144 Cm @Xyvoss_1.m4a",
  "samples/2. answers v2 [107bpm] (ninetyniiine)_2.m4a",
  "samples/7. Gas Station (98 Bpm C#min)_NOIS x BB__1.m4a",
  "samples/8. see it through - 120 - Cminor_NOIS x BB__1.m4a",
  "samples/AAA_Chord_Loop_10_90_Cm_1.m4a",
  "samples/abroad 133 Cmin @stuzzy + @laguneqt_1.m4a",
  "samples/BOS_ISL_85_Instrument_Qanun_Loop_Sultan_Cm_1.m4a",
  "samples/BOS_ISL_100_Instrument_Piano_Loop_Retro_Land_Cm_1.m4a",
  "samples/BOS_ISL_110_Instrument_Piano_Loop_Royal_Cm_1.m4a",
  "samples/light it up [185 Cmin] (@prod_prxphecy)_1.m4a",
  "samples/RAINMAN 2 (SMILE) (90 BPM) Dystinkt x Joezee [Loop]_1.m4a",
  "samples/SA Misyats 140 Cmin @ndacompany @ahn (Synth+Virtual+Sad)_1.m4a",
  "samples/spectra_instruments_guitar_loop_arpPluckRiff_88_Cmin_1.m4a",
  "samples/strangers _132bpm _c min (pads, synths) @prodsky (1)_1.m4a",
  "samples/suffer again - 152 bpm @ezra.abbey x Its2ezzy_1.m4a",
  "samples/TSP_HE_90_melodic_instrumental_found_Cmin_1.m4a",
  "samples/VOODOO (160 BPM) Dystinkt Beats_1.m4a",
];

const snaresUrls = [
  "snares/BNYX CLAPS_2.m4a",
  "snares/BNYX CLAPS.m4a",
  "snares/BNYX SNRS_2.m4a",
  "snares/BNYX SNRS.m4a",
  "snares/JAHLIL SNARES_1.m4a",
  "snares/nois rim snr kit_1.m4a",
  "snares/nois rim snr kit_2.m4a",
  "snares/nois rim snr kit_3.m4a",
  "snares/nois rim snr kit_4.m4a",
  "snares/nois rim snr kit_6.m4a",
  "snares/nois rim snr kit.m4a",
  "snares/NOIS_2022_Snares__2.m4a",
  "snares/NOIS_2022_Snares_.m4a",
];

type AudioFile = {
  id: number;
  startPosition: number;
  loopIndex: number;
  name: string;
  url: string;
  draggable?: boolean;
  options?: Record<string, string>;
};

const handleBlob = async (url: string) => {
  try {
    const blob = await readStream(baseUrl + url, undefined, "audio/x-m4a");
    const urlObject = URL.createObjectURL(blob);
    return urlObject;
  } catch (e) {
    console.log("failed to load", url);
    return null;
  }
};

const BeatMatrixScreen = () => {
  const { palette } = useTheme();
  const beatMatrixColors = useBeatMatrixColors();
  const STARTING_HATS_INDEX = Math.floor(Math.random() * hatsUrls.length);
  const STARTING_KICK_INDEX = Math.floor(Math.random() * kickUrls.length);
  const STARTING_SAMPLE_INDEX = Math.floor(Math.random() * sampleUrls.length);
  const STARTING_SNARE_INDEX = Math.floor(Math.random() * snaresUrls.length);
  const STARTING_VOCALS_INDEX = Math.floor(Math.random() * vocalsUrls.length);
  const STARTING_PERCUSSION_INDEX = Math.floor(
    Math.random() * percussionUrls.length,
  );
  const STARTING_BASS_INDEX = Math.floor(Math.random() * bassUrls.length);
  const [beatName, setBeatName] = useState<string>("");
  const muteState = useRef([false, true, true, true, true, true, true]);
  const volumeState = useRef<number[]>(
    muteState.current.map((isMuted) => (isMuted ? 0 : 0.5)),
  );
  const setShowTopNav = useSetAtom(showTopNavAtom);

  useEffect(() => {
    setShowTopNav(undefined);
    return () => {
      setShowTopNav(true);
    };
  }, [setShowTopNav]);

  const { isDesktop } = useMediaQueryBreakpoint();
  const [audioFiles, setAudioFiles] = useState<AudioFile[]>([
    {
      id: 0,
      startPosition: 0,
      name: "Sample",
      loopIndex: STARTING_SAMPLE_INDEX,
      url: sampleUrls[STARTING_SAMPLE_INDEX],
      options: {
        waveColor: beatMatrixColors(0),
      },
    },
    {
      id: 1,
      startPosition: 0,
      name: "Bass",
      loopIndex: STARTING_BASS_INDEX,
      url: bassUrls[STARTING_BASS_INDEX],
      options: {
        waveColor: beatMatrixColors(1),
      },
    },
    {
      id: 2,
      startPosition: 0,
      name: "Kick",
      loopIndex: STARTING_KICK_INDEX,
      url: kickUrls[STARTING_KICK_INDEX],
      options: {
        waveColor: beatMatrixColors(2),
      },
    },
    {
      id: 3,
      startPosition: 0,
      name: "Snare",
      loopIndex: STARTING_SNARE_INDEX,
      url: snaresUrls[STARTING_SNARE_INDEX],
      options: {
        waveColor: beatMatrixColors(3),
      },
    },
    {
      id: 4,
      startPosition: 0,
      name: "Hi Hats",
      draggable: false,
      loopIndex: STARTING_HATS_INDEX,
      url: hatsUrls[STARTING_HATS_INDEX],
      options: {
        waveColor: beatMatrixColors(4),
      },
    },
    {
      id: 5,
      startPosition: 0,
      name: "Percussion",
      loopIndex: STARTING_PERCUSSION_INDEX,
      url: percussionUrls[STARTING_PERCUSSION_INDEX],
      options: {
        waveColor: beatMatrixColors(5),
      },
    },
    {
      id: 6,
      startPosition: 0,
      name: "Vocals",
      loopIndex: STARTING_VOCALS_INDEX,
      url: vocalsUrls[STARTING_VOCALS_INDEX],
      options: {
        waveColor: beatMatrixColors(6),
      },
    },
  ]);
  const [localHatsUrls, setLocalHatsUrls] = useState<string[]>(
    shuffle(hatsUrls).slice(),
  );
  const [localKicksUrls, setLocalKicksUrls] = useState<string[]>(
    shuffle(kickUrls).slice(),
  );
  const [localSnaresUrls, setLocalSnaresUrls] = useState<string[]>(
    shuffle(snaresUrls).slice(),
  );
  const [localSampleUrls, setLocalSampleUrls] = useState<string[]>(
    shuffle(sampleUrls).slice(),
  );
  const [localVocalsUrls, setLocalVocalsUrls] = useState<string[]>(
    shuffle(vocalsUrls).slice(),
  );
  const [localPercussionUrls, setLocalPercussionUrls] = useState<string[]>(
    shuffle(percussionUrls).slice(),
  );
  const [localBassUrls, setLocalBassUrls] = useState<string[]>(
    shuffle(bassUrls).slice(),
  );
  const [submittingBeat, setSubmittingBeat] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let newAudioFiles: AudioFile[] = [];
    const preloadAudio = async () => {
      newAudioFiles = await Promise.all(
        audioFiles.map(async (audio) => {
          const blob = await readStream(
            baseUrl + audio.url,
            undefined,
            "audio/x-m4a",
          );
          const url = URL.createObjectURL(blob);
          return { ...audio, url };
        }),
      );
      setAudioFiles(newAudioFiles);

      const newKicksUrls = await Promise.all(
        kickUrls.map(async (url) => handleBlob(url)),
      );
      const updatedKicks = newKicksUrls.filter((url) => url !== null);
      setLocalKicksUrls(updatedKicks);

      const newSnaresUrls = await Promise.all(
        snaresUrls.map(async (url) => handleBlob(url)),
      );
      const updatedSnares = newSnaresUrls.filter((url) => url !== null);
      setLocalSnaresUrls(updatedSnares);

      const newHatsUrls = await Promise.all(
        hatsUrls.map(async (url) => handleBlob(url)),
      );
      const updatedHats = newHatsUrls.filter((url) => url !== null);
      setLocalHatsUrls(updatedHats);

      const newSampleUrls = await Promise.all(
        sampleUrls.map(async (url) => handleBlob(url)),
      );
      const updatedSample = newSampleUrls.filter((url) => url !== null);
      setLocalSampleUrls(updatedSample);

      const newVocalsUrls = await Promise.all(
        vocalsUrls.map(async (url) => handleBlob(url)),
      );
      const updatedVocals = newVocalsUrls.filter((url) => url !== null);
      setLocalVocalsUrls(updatedVocals);

      const newPercussionUrls = await Promise.all(
        percussionUrls
          .map(async (url) => handleBlob(url))
          .filter((url) => url !== null),
      );
      const updatedPercussion = newPercussionUrls.filter((url) => url !== null);
      setLocalPercussionUrls(updatedPercussion);

      const newBassUrls = await Promise.all(
        bassUrls.map(async (url) => handleBlob(url)),
      );
      const updatedBass = newBassUrls.filter((url) => url !== null);
      setLocalBassUrls(updatedBass);
    };
    preloadAudio().then(() => {
      setSelectedFiles(newAudioFiles);
    });
  }, []);

  const [selectedFiles, setSelectedFiles] = useState<AudioFile[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const multiTrackRef = useRef<MultiTrack>();
  const handlePauseAll = () => {
    if (!multiTrackRef.current) return;
    setSelectedFiles([...selectedFiles]);
    setIsPlaying(false);
  };

  const handlePlayAll = () => {
    if (!multiTrackRef.current) return;
    if (!multiTrackRef.current.isPlaying() && canPlay) {
      multiTrackRef.current.setTime(0);
      multiTrackRef.current?.play();
      muteState.current.forEach((mute, index) => {
        multiTrackRef.current?.setTrackVolume(
          index,
          mute ? 0 : volumeState.current[index],
        );
      });
    }
    setIsPlaying(true);
  };

  const getLocalUrlsForIndex = (index: number) => {
    switch (index) {
      case 0:
        return localSampleUrls;
      case 1:
        return localBassUrls;
      case 2:
        return localKicksUrls;
      case 3:
        return localSnaresUrls;
      case 4:
        return localHatsUrls;
      case 5:
        return localPercussionUrls;
      case 6:
        return localVocalsUrls;
      default:
        return [];
    }
  };

  const getBaseUrlsForIndex = (index: number) => {
    switch (index) {
      case 0:
        return sampleUrls;
      case 1:
        return bassUrls;
      case 2:
        return kickUrls;
      case 3:
        return snaresUrls;
      case 4:
        return hatsUrls;
      case 5:
        return percussionUrls;
      case 6:
        return vocalsUrls;
      default:
        return [];
    }
  };

  const handleShuffle = (index: number) => {
    setSelectedFiles(
      selectedFiles.map((file, i) => {
        if (i === index) {
          const { loopIndex, ...rest } = file;
          const urls = getLocalUrlsForIndex(i);
          const newLoopIndex = (loopIndex || 0) + 1;
          return {
            ...rest,
            loopIndex: newLoopIndex >= urls.length - 1 ? 0 : newLoopIndex,
            url: urls[newLoopIndex],
          };
        }
        return file;
      }),
    );
  };

  // Official doc:  https://wavesurfer-multitrack.pages.dev/docs/types/MultitrackOptions
  useEffect(() => {
    if (!containerRef.current) return;
    if (selectedFiles.length === 0) return;
    if (multiTrackRef.current) {
      multiTrackRef.current.destroy();
      setCanPlay(false);
    }
    const multiTrack = Multitrack.create(selectedFiles, {
      container: containerRef.current, // required!
      minPxPerSec: 5, // zoom level
      rightButtonDrag: false, // set to true to drag with right mouse button
      cursorWidth: 2,
      cursorColor: "black",
      trackBorderColor: "#7C7C7C00",
      dragBounds: true,
      envelopeOptions: {
        lineColor: "rgba(255, 0, 0, 0.7)",
        lineWidth: "4",
        dragPointSize: window.innerWidth < 600 ? 20 : 10,
        dragPointFill: "rgba(255, 255, 255, 0.8)",
        dragPointStroke: "rgba(255, 255, 255, 0.8)",
      },
    });
    if (!multiTrack) return;
    multiTrackRef.current = multiTrack;
    multiTrackRef.current?.once("canplay", () => {
      setCanPlay(true);
      multiTrack.setTime(0);
      if (isPlaying) {
        multiTrack.play();
        muteState.current.forEach((mute, index) => {
          multiTrack.setTrackVolume(
            index,
            mute ? 0 : volumeState.current[index],
          );
        });
      }
    });
  }, [selectedFiles]);

  useEffect(() => {
    return () => {
      multiTrackRef.current?.destroy();
    };
  }, []);

  return (
    <Container
      sx={{
        backgroundColor: palette.background.paper,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: "32px",
        height: "100vh",
      }}
      id="beat-matrix-screen"
    >
      {isDesktop && (
        <Box
          sx={{
            maxWidth: 300,
            width: "100%",
            flex: 1,
            height: "100vh",
            backgroundColor: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={beatMatrixLeftSide} alt="beat matrix left side" />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          flex: 1,
          maxWidth: "764px",
        }}
      >
        {/* Title and Controls */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "16px",
            marginTop: "16px",
            gap: "32px",
          }}
        >
          {/* Title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <h3 style={{ marginRight: "8px" }}>Beat Matrix</h3>
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                backgroundColor: theme.palette.standardColor.BoomyOrange[400],
                borderRadius: "8px",
                color: theme.palette.primary.contrastText,
              })}
            >
              <Text
                sx={(theme) => ({
                  color: theme.palette.primary.contrastText,
                  lineHeight: "1.5rem",
                })}
                variant={TextStyleVariant.H6}
              >
                BETA
              </Text>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
              textAlign: "center",
            }}
          >
            <Text variant={TextStyleVariant.P1} sx={{ textAlign: "center" }}>
              <strong>
                {"Engineears AI-Powered Beat Generation".toUpperCase()}
              </strong>
            </Text>
            <Text sx={{ textAlign: "center" }} variant={TextStyleVariant.P2}>
              Create a custom beat from millions of musical combinations that no
              one’s heard before.
            </Text>
          </Box>
          <BeatMatrixDropdown />

          {/* Playback control section*/}
          <Box
            sx={(theme) => ({
              display: "flex",
              backgroundColor: theme.palette.customColor.midgroundColor,
              alignItems: "center",
              justifyContent: "center",
              boxSizing: "border-box",
              gap: "16px",
              maxWidth: "566px",
              width: "100%",
              flex: 1,
              borderRadius: "16px",
            })}
          >
            <PlaybackControlButton
              variant={
                isPlaying
                  ? PlaybackControlButtonVariant.PAUSE
                  : PlaybackControlButtonVariant.PLAY_CIRCLE
              }
              style={{ height: "28px", width: "28px" }}
              disabled={false}
              isPrimary
              onClick={() => {
                if (multiTrackRef.current?.isPlaying()) {
                  handlePauseAll();
                } else {
                  handlePlayAll();
                }
              }}
            />
            <TextField
              value={beatName}
              placeholder="Input Player Name & Beat Name"
              slotProps={{
                input: {
                  disableUnderline: true,
                },
              }}
              sx={{ textDecoration: "none", color: palette.text.primary }}
              variant="standard"
              onChange={(e) => setBeatName(e.target.value)}
              fullWidth
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              loading={submittingBeat}
              disabled={submittingBeat || !beatName}
              onClick={() => {
                setSubmittingBeat(true);
                handlePauseAll();
                dispatch(
                  submitBeatMatrixBeat({
                    beat_name: beatName,
                    selections: selectedFiles.map((file, index) => ({
                      path: getBaseUrlsForIndex(index)[file.loopIndex],
                      volume: muteState.current[index]
                        ? 0
                        : volumeState.current[index],
                    })),
                  }),
                )
                  .then(unwrapResult)
                  .then((response: SubmitBeatMatrixResponse) => {
                    toast.success("Beat submitted successfully!");
                  })
                  .finally(() => {
                    setSubmittingBeat(false);
                  });
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
        {/* Beat Matrix Tracks */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              marginRight: "8px",
              marginTop: "16px",
            }}
          >
            {selectedFiles.map((file, index) => (
              <Box
                key={file.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                  width: "100%",
                }}
              >
                <BeatMatrixMute
                  multiTrackRef={multiTrackRef}
                  muteState={muteState}
                  volumeState={volumeState}
                  index={index}
                  beatMatrixColors={beatMatrixColors}
                />
              </Box>
            ))}
          </Box>
          <Box
            className="multi-track-player-unique-caller"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "9px",
              marginTop: "14px",
              maxWidth: "100%",
              height: "100%",
              flex: 1,
              position: "relative",
            }}
          >
            <div
              className="multi-track-unique"
              ref={containerRef}
              id="multi-track-player"
              style={{
                position: "absolute",
                display: "flex",
                maxHeight: "400px",
                maxWidth: "100%",
                width: "100%",
                flex: 1,
              }}
            />
            {selectedFiles.map((file, index) => (
              <Box
                key={file.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  flex: 1,
                }}
              >
                <Box
                  sx={{
                    height: "49px",
                    padding: "8px",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    width: "100%",
                    backgroundColor: beatMatrixColors(index),
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 100,
                    }}
                  >
                    <Text bold>{file.name}</Text>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "13px",
              marginLeft: "8px",
              gap: "10px",
            }}
          >
            {selectedFiles.map((file, index) => (
              <Box
                key={file.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* Buttons */}
                <Button
                  onClick={() => {
                    if (!multiTrackRef.current) return;
                    handleShuffle(index);
                  }}
                  variant={ButtonVariant.UNSTYLED}
                >
                  <Box
                    alignSelf="stretch"
                    padding={"12px"}
                    sx={() => ({
                      backgroundColor: beatMatrixColors(index),
                      borderRadius: "8px",
                    })}
                  >
                    <FontAwesomeIcon icon={faArrowsRotate} size="xl" />
                  </Box>
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {isDesktop && (
        <Box
          sx={{
            maxWidth: 300,
            width: "100%",
            flex: 1,
            height: "100%",
            backgroundColor: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={beatMatrixRightSide} alt="beat matrix right side" />
        </Box>
      )}
    </Container>
  );
};

export default BeatMatrixScreen;
