import { atom, Getter, Setter } from "jotai";
import { atomWithLocation } from "jotai-location";
import pathnameAtom from "./pathnameAtom";
import { dashboardSidePanelAtom } from "../../stories/screens/Dashboard/atoms";
import InviteTeammateButton from "../../stories/components/InviteTeammate/InviteTeammateButton";
import { reduxAtom } from "../../store";
import {
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from "../../store/models/subscription";

const _searchQueryAtom = atomWithLocation({ replace: true });
_searchQueryAtom.debugPrivate = false;

// The purpose of this interface is to define the expected shape of the search parameters that can be passed to a search function.
export interface ExpectedSearchParams {
  term?: string;
  step?: string;
  flow?: string;
  status?: string;
  page?: string;
  channel?: string;
  code?: string;

  [x: string]: string | undefined;
}

// This represents a key in the url, the value in the url, and a boolean on whether or not the key should be cleared from the URL when being set
export interface SetSearchParams<T> {
  key: keyof ExpectedSearchParams;
  value: T;
  clear?: boolean;
}

const setSearchParamsHelper = (
  get: Getter,
  set: Setter,
  params: SetSearchParams<string> | SetSearchParams<string>[],
) => {
  set(_searchQueryAtom, (prev) => {
    const newParams = new URLSearchParams(location.search);

    const setParam = (param: SetSearchParams<string>) => {
      if (param.clear && !param.value) {
        newParams.delete(String(param.key));
      } else {
        newParams.set(String(param.key), param.value);
      }
    };

    if (Array.isArray(params)) {
      params.forEach(setParam);
    } else {
      setParam(params);
    }

    return { ...prev, searchParams: newParams, pathname: get(pathnameAtom) };
  });
};

// parses the URL parameters into an object that can only be read from.
const searchParamsAtom = atom((get) => {
  const currentParams = get(_searchQueryAtom).searchParams;
  const validatedParams: ExpectedSearchParams = {};
  currentParams?.forEach((value, key) => {
    validatedParams[key] = value;
  });
  return validatedParams;
});

// a read-only atom that returns the URL-safe query string
const queryStringAtom = atom((get) => {
  const params = get(searchParamsAtom) || {};
  return Object.keys(params)
    .map((key) => {
      if (typeof params[key] !== "undefined") {
        return `${key}=${encodeURIComponent(params[key] || "")}`;
      }
      return "";
    })
    .join("&");
});

queryStringAtom.debugLabel = "URL-Safe Query String";

export enum PAGE_HASH {
  INVITE_TEAM = "#inviteTeam",
  ADD_ROOM = "#addRoom",
  EDIT_STUDIO = "#editStudio",
  EDIT_ROOM = "#editRoom",
  CLEARED = "#cleared",
}

export const hashAtom = atom<string | undefined>(undefined);

export const openAddRoomModalAtom = atom(
  (get) => get(hashAtom) === PAGE_HASH.ADD_ROOM,
  (_, set, value: boolean) => {
    set(hashAtom, value ? PAGE_HASH.ADD_ROOM : PAGE_HASH.CLEARED);
  },
);

export const openEditStudioModalAtom = atom(
  (get) => get(hashAtom) === PAGE_HASH.EDIT_STUDIO,
  (_, set, value: boolean) => {
    set(hashAtom, value ? PAGE_HASH.EDIT_STUDIO : PAGE_HASH.CLEARED);
  },
);

export const openEditRecordingServiceModalAtom = atom(
  (get) => get(hashAtom) === PAGE_HASH.EDIT_ROOM,
  (_, set, value: boolean) => {
    set(hashAtom, value ? PAGE_HASH.EDIT_ROOM : PAGE_HASH.CLEARED);
  },
);

export const openInviteTeammatePanelAtom = atom(
  (get) => get(hashAtom) === PAGE_HASH.INVITE_TEAM,
  (_, set, value: boolean) => {
    set(hashAtom, value ? PAGE_HASH.INVITE_TEAM : PAGE_HASH.CLEARED);
  },
);

// this atom will set all the panel atoms
// mainly to be used on close for modulardashboard panels
export const sidePanelHashControlAtom = atom(
  (get) => get(openInviteTeammatePanelAtom),
  (_, set, value: boolean) => {
    set(openInviteTeammatePanelAtom, value);
  },
);

export { queryStringAtom, searchParamsAtom, setSearchParamsHelper };
