import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import {
  a11yProps,
  AccountSettingsModalTabs,
  accountSettingsTabs,
} from "./utils";
import {
  EETab,
  EETabs,
  TabsBottomGreyBar,
} from "../../screens/ProfileScreen/ProfileScreen.styles";
import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Text } from "../../core-ui/components/Text/Text";
import {
  getDisplayableNameForStudio,
  getDisplayableNameForUser,
} from "../../../store/utils/entityUtils";
import { useAppSelector } from "../../../store/hooks";
import { StyledAccountSettingsFormModalBody } from "./AccountSettingsModal.styles";
import { accountSettingsModalOpenAtom } from "./atoms";
import { useAtom } from "jotai";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { DISCIPLINE_TYPE, getUserDisciplines } from "../../../hooks/user";

interface AccountSettingsModalProps {
  onClose: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const AccountSettingsModal = ({
  onClose,
}: AccountSettingsModalProps) => {
  const theme = useTheme();
  const { user } = useAppSelector((state) => state.accountInfo);
  const userDiscpilineTypes = getUserDisciplines(user);
  const selectedProfile = useAppSelector((state) => state.selectedProfileSlice);
  const displayName = getDisplayableNameForUser(selectedProfile?.user);
  const studioName = getDisplayableNameForStudio(selectedProfile?.studio);
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    AccountSettingsModalTabs.AccountDetails,
  );
  const [isAccountSettingsModalOpen, setIsAccountSettingsModalOpen] = useAtom(
    accountSettingsModalOpenAtom,
  );

  const handleSetSelectedTabIndex = (
    _: React.SyntheticEvent,
    tabIndex: number,
  ) => {
    setSelectedTabIndex(tabIndex);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        style={{ width: "100%" }}
        {...other}
      >
        {value === index && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
      </div>
    );
  };

  if (!user) {
    return null;
  }

  return (
    <BaseModal
      open={isAccountSettingsModalOpen}
      setOpen={() => setIsAccountSettingsModalOpen(false)}
      header={`Account Settings for ${
        selectedProfile?.studio ? studioName : displayName
      }`}
      style={{ width: "100%", maxWidth: "unset", height: "100%" }}
      customBodyStyle={StyledAccountSettingsFormModalBody}
      customModalStyle={{
        boxSizing: "border-box",
        width: "90%",
        maxWidth: "800px",
      }}
      fullScreenOnMobile
    >
      <Box
        sx={(theme) => ({
          position: "relative",
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        })}
      >
        <TabsBottomGreyBar />
        <EETabs
          value={selectedTabIndex}
          onChange={handleSetSelectedTabIndex}
          aria-label="Account Settings Navigation Tab"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {accountSettingsTabs.map((tab, index) => {
            if (
              tab.value === AccountSettingsModalTabs.PaymentAndBilling &&
              !userDiscpilineTypes.includes(DISCIPLINE_TYPE.ENGINEER) &&
              !userDiscpilineTypes.includes(DISCIPLINE_TYPE.STUDIO_MANAGER)
            ) {
              return null;
            }
            const tabLabel = (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Text
                  variant={TextStyleVariant.P1}
                  style={{
                    color:
                      selectedTabIndex === tab.value
                        ? theme.palette.text.primary
                        : theme.palette.standardColor.Gray[400],
                  }}
                  bold
                >
                  {tab.label}
                </Text>
              </Box>
            );

            return (
              <EETab key={tab.value} label={tabLabel} {...a11yProps(index)} />
            );
          })}
        </EETabs>
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {accountSettingsTabs.map((tab) => (
          <TabPanel key={tab.value} value={selectedTabIndex} index={tab.value}>
            {tab.content(onClose)}
          </TabPanel>
        ))}
      </Box>
    </BaseModal>
  );
};
