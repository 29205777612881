import { useTheme } from "@mui/material";

export const useBeatMatrixColors = () => {
  const { palette } = useTheme();
  const colors: Record<number, { default: string; disabled: string }> = {
    0: {
      default: palette.standardColor.BoxyYellow[200],
      disabled: palette.standardColor.BoxyYellow[100],
    },
    1: {
      default: palette.standardColor.DeepBlue[200],
      disabled: palette.standardColor.DeepBlue[100],
    },
    2: {
      default: palette.standardColor.Green[200],
      disabled: palette.standardColor.Green[100],
    },
    3: {
      default: palette.standardColor.Red[200],
      disabled: palette.standardColor.Red[100],
    },
    4: {
      default: palette.standardColor.BoomyOrange[200],
      disabled: palette.standardColor.BoomyOrange[100],
    },
    5: {
      default: palette.standardColor.DeepGreen[200],
      disabled: palette.standardColor.DeepGreen[100],
    },
    6: {
      default: palette.standardColor.Gray[200],
      disabled: palette.standardColor.Gray[100],
    },
  };

  return (index: number, isDisabled: boolean = false) => {
    return isDisabled ? colors[index].disabled : colors[index].default;
  };
};
