import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { updateStudioRoomSearchPage } from "../../store/actions/studioRoomSearch";
import { updateUserSearchPage } from "../../store/actions/userSearch";
import { useAppDispatch } from "../../store/hooks";
import { parseUrlQueryParams } from "../../utils/searchFiltersUtils";
import { SearchFiltersQueryParams } from "./utils";

export const useUpdateSearchFilters = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  return ({
    updatedSearchQueryObject,
    newPathname,
    resetPagination = false,
  }: {
    updatedSearchQueryObject: SearchFiltersQueryParams;
    newPathname?: string;
    resetPagination?: boolean;
  }) => {
    const parsedQueryParams = parseUrlQueryParams();
    const updatedQueryParamsString = queryString.stringify(
      {
        ...parsedQueryParams,
        ...updatedSearchQueryObject,
      },
      { skipNull: true, skipEmptyString: true },
    );

    history.push({
      pathname: newPathname,
      search: updatedQueryParamsString,
    });

    if (resetPagination) {
      dispatch(updateUserSearchPage(1));
      dispatch(updateStudioRoomSearchPage(1));
    }
  };
};
