import {
  faChevronDown,
  faCircle,
  faCircleCheck,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as faCircleBorder } from "@fortawesome/sharp-regular-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChecklistItemResponse } from "../../../hooks/onboardingHooks/getOnboardingProgress";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { useAtomValue, useSetAtom } from "jotai";
import { showChecklistAtom } from "./StickyOnboardingChecklist";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import {
  OnboardingChecklistItemEnum,
  useUpdateOnboardingProgressMutation,
} from "../../../hooks/onboardingHooks/useUpdateOnboardingProgressMutation";
import { activeUserAtom } from "../../../atoms/user/activeUserAtom";
import { BasePopover } from "../../core-ui/components/BasePopover/BasePopover";
import useModal from "../../../hooks/useModal";
import * as Popover from "@radix-ui/react-popover";
import { activeUserStudiosAtom } from "../../../atoms/user/activeUserStudiosAtom";
import { useHistory } from "react-router-dom";
import { activeProfileAtom } from "../../../atoms/user/activeProfileAtom";

export interface ChecklistItemProps extends ChecklistItemResponse {
  itemIndex: number;
  currentExpandedItem: number | boolean;
  handleChecklistItemExpand: (
    index: number,
  ) => (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void;
}

const disabledOpacity = 0.38;

const ChecklistItem = ({
  item_key: itemKey,
  title,
  description,
  expandable,
  completed,
  button_content: buttonContent,
  guide,
  currentExpandedItem,
  handleChecklistItemExpand,
  itemIndex,
}: ChecklistItemProps) => {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const link = buttonContent?.link || "";
  const [baseUrl, searchParam] = link.split("?");
  const setShowChecklist = useSetAtom(showChecklistAtom);
  const setActiveProfile = useSetAtom(activeProfileAtom);
  const userStudios = useAtomValue(activeUserStudiosAtom);
  const studioId =
    userStudios.length > 0 ? userStudios[0].id.toString() : undefined;
  const slimUser = useAtomValue(activeUserAtom);
  const { mutateAsync: updateOnboardingProgress } =
    useUpdateOnboardingProgressMutation({
      userId: slimUser?.id,
    });
  const {
    setIsOpen: setPopoverOpen,
    isOpen: popoverOpen,
    closeModal: closePopover,
  } = useModal();

  const popConfirmText: Partial<{
    [key in OnboardingChecklistItemEnum]: React.ReactNode;
  }> = {
    [OnboardingChecklistItemEnum.PROFILE_SHARED]: (
      <Text>
        Have you shared your EngineEars profile and these{" "}
        <Link href={baseUrl} target="_blank" rel="noopener">
          artist benefits
        </Link>{" "}
        with your client?
      </Text>
    ),
    [OnboardingChecklistItemEnum.LINK_ADDED_TO_SOCIALS]: (
      <Text>
        Have you added your EngineEars profile link to your social bios so you
        can{" "}
        <Link href={baseUrl} target="_blank" rel="noopener">
          attract client bookings
        </Link>
        ?
      </Text>
    ),
  };

  const buttonClickMarksItemComplete = [
    OnboardingChecklistItemEnum.PROFILE_SHARED,
    OnboardingChecklistItemEnum.LINK_ADDED_TO_SOCIALS,
  ].includes(itemKey);

  // TODO: Clean this code up, this should be done via linking from the BE.
  const shouldGoToStudioDashboard = [
    OnboardingChecklistItemEnum.INVITE_TEAM,
    OnboardingChecklistItemEnum.STUDIO_BOOKING_GENERATED,
  ].includes(itemKey);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    if (shouldGoToStudioDashboard) {
      if (studioId && buttonContent?.link) {
        setActiveProfile(studioId);
        history.push(buttonContent.link);
      }
      return;
    }
    if (buttonClickMarksItemComplete) {
      setPopoverOpen(true);
    }
    if (isMobile && !buttonClickMarksItemComplete) {
      setShowChecklist(false);
    }
    emitAnalyticsTrackingEvent(
      "checklist_action_" + title.toLowerCase().replace(/ /g, "_"),
      {},
    );
  };

  const handlePopoverConfirm = async () => {
    await updateOnboardingProgress(itemKey);
  };

  return (
    <Accordion
      sx={{
        "&::before": { display: "none" },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.customColor.foregroundColor,
        },
        boxShadow: "none",
        borderRadius: "0 !important",
        backgroundColor: theme.palette.background.paper,
      }}
      disabled={!expandable}
      disableGutters
      expanded={currentExpandedItem === itemIndex}
      onChange={handleChecklistItemExpand(itemIndex)}
    >
      <AccordionSummary
        sx={{
          opacity: completed ? disabledOpacity : 1,
          "&.Mui-disabled": {
            opacity: completed ? disabledOpacity : 1,
          },
        }}
        {...(expandable && {
          expandIcon: <FontAwesomeIcon icon={faChevronDown} />,
        })}
      >
        <Checkbox
          icon={
            currentExpandedItem === itemIndex ? (
              <FontAwesomeIcon
                color={theme.palette.standardColor.BoomyOrange[50]}
                stroke={theme.palette.standardColor.BoomyOrange[500]}
                strokeWidth={45}
                icon={faCircleBorder}
              />
            ) : (
              <FontAwesomeIcon
                color={theme.palette.standardColor.Gray[50]}
                icon={faCircle}
              />
            )
          }
          checkedIcon={
            <FontAwesomeIcon
              color={theme.palette.standardColor.Green[600]}
              icon={faCircleCheck}
            />
          }
          checked={completed}
          disableRipple
          sx={{ padding: "0 9px 0 0" }}
        />
        <Text variant={TextStyleVariant.P1}>{title}</Text>
      </AccordionSummary>
      <AccordionDetails sx={{ opacity: completed ? disabledOpacity : 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            paddingLeft: "32px",
          }}
        >
          <Text variant={TextStyleVariant.P2}>{description}</Text>
          {buttonContent && (
            <>
              <BasePopover
                isOpen={popoverOpen}
                onConfirm={handlePopoverConfirm}
                closePopover={closePopover}
                setIsPopoverOpen={setPopoverOpen}
                description={popConfirmText[itemKey]}
              >
                <Popover.Anchor></Popover.Anchor>
              </BasePopover>
              <Button
                href={
                  buttonClickMarksItemComplete || shouldGoToStudioDashboard
                    ? "#"
                    : baseUrl
                }
                search={searchParam}
                variant={ButtonVariant.OUTLINED}
                style={{ width: "inherit" }}
                onClick={handleButtonClick}
              >
                {buttonContent.label}
              </Button>
            </>
          )}
          {guide && (
            <Button
              href={guide.link}
              variant={ButtonVariant.UNSTYLED}
              style={{ textDecoration: "underline", gap: "5px" }}
              onClick={() => {
                emitAnalyticsTrackingEvent(
                  "checklist_help_" + title.toLowerCase().replace(/ /g, "_"),
                  {},
                );
              }}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              <Text weight={TEXT_WEIGHT.BOLD} variant={TextStyleVariant.P2}>
                {guide.title}
              </Text>
            </Button>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ChecklistItem;
