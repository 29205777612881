import { useEffect } from "react";
import {
  getDisciplineString,
  getPrimaryDiscipline,
  useUserDisciplinesString,
} from "../../../../hooks/user";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  engineerOnboardedDateSelector,
  isEngineerOnboardedSelector,
  isEngineerVerifiedSelector,
  selectUserCanEnableStudioServices,
} from "../../../../store/selectors/userInfoSelectors";
import {
  getDebugEventUserIdPrefix,
  identifyAuthenticatedUser,
} from "../../../../utils/analyticsUtils";

const useIdentifyUser = () => {
  const dispatch = useAppDispatch();
  const accountInfo = useAppSelector((state) => state.accountInfo);
  const verifiedEngineer = useAppSelector(isEngineerVerifiedSelector);
  const subscriptionPlanChoice = useAppSelector(
    (state) => state.subscriptionStore.subscription_plan_choice,
  );
  const onboardedEngineer = useAppSelector(isEngineerOnboardedSelector);
  const canManageStudios = useAppSelector(selectUserCanEnableStudioServices);
  const engineerOnboardedDate = useAppSelector(engineerOnboardedDateSelector);
  const { isAuthenticated, user } = accountInfo;
  const [userDisciplinesString] = useUserDisciplinesString(user);
  useEffect(() => {
    if (isAuthenticated && user) {
      identifyAuthenticatedUser(
        user.id,
        {
          user_id: `${getDebugEventUserIdPrefix}${user.id}`,
          username: `${user.username}`,
          display_name: `${user.profile?.display_name}`,
          email: `${user.email}`,
          firstname: `${user.first_name}`,
          lastname: `${user.last_name}`,
          date_joined: `${user.date_joined}`,
          utm_source: `${user.utm_source}`,
          utm_medium: `${user.utm_medium}`,
          utm_campaign: `${user.utm_campaign}`,
          utm_content: `${user.utm_content}`,
          utm_term: `${user.utm_term}`,
          account_type: `${getDisciplineString(getPrimaryDiscipline(user))}`,
          verified_engineer: `${verifiedEngineer}`,
          engineer_subscription: `${subscriptionPlanChoice}`,
          started_onboarding: `${onboardedEngineer}`,
          started_onboarding_date: `${engineerOnboardedDate}`,
          all_account_types: `${userDisciplinesString}`,
          can_manage_studios: `${canManageStudios}`,
        },
        user.anonymous_id || window.analytics?.user?.()?.anonymousId(),
      );
    }
  }, [
    user,
    isAuthenticated,
    verifiedEngineer,
    subscriptionPlanChoice,
    onboardedEngineer,
    userDisciplinesString,
    dispatch,
    canManageStudios,
    engineerOnboardedDate,
  ]);
};

export default useIdentifyUser;
